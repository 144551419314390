import React, { useState } from "react";
import styled from "styled-components";
import { X, Plus } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import { WrapperSmall, ButtonRoundedText, ButtonRoundedLight, StyledInput, StyledInputWeekly, Wrapper, ButtonRoundedTextGray } from "../components/styles/GlobalStyles";
import { motion } from "framer-motion";
import { handleSendEmail, sendSlackNotification, handleSendUserEmail } from "../helpers";
import Autocomplete from 'react-google-autocomplete';
import toast, { Toaster } from 'react-hot-toast';
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import arrow from "../images/Arrow.svg";
import logo from "../images/logoAlt.svg";
import tick from "../images/tick.svg";

// Styled components
const StyledInputWrapper = styled.div`
  position: relative;
  display: block;
  &::after {
    content: "$";
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-weight: 500;
    font-size: 1em;
  }
`;

const StyledSection = styled(motion.section)`
  height: auto;
  width: 100%;
  z-index: 100;
  margin-bottom:160px;
  padding-top: 120px;
  .titleContainer {
    margin-bottom: 2em;
    h2 {
      margin-bottom: .2em;
    }
  }
  div {
    position: relative;
    label {
      text-align: left;
      padding: 1em 0;
      font-weight: 600;
    }
  }
`;

const AddedPropertyCard = styled.div`
  width: 100%;
  transition: all 0.16s ease-out;
  background-color: white;
  padding: 1em;
  box-sizing: border-box;       
  box-shadow: 0px 4px 29px 0px rgba(255, 199, 1, 0.16);
  border-radius: 4px;
  border: 1px solid #dedede;
  margin-bottom: 20px;
  position: relative;
`;

const AddedPropertyContent = styled.div`
  transition: all 1s ease-out;
`;

const AddedPropertyTitle = styled.h4`
  font-weight: 600;
  font-size: 1.24em;
  margin: 0 0 0.5em 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 40px;
`;

const AddedPropertyDescription = styled.p`
  margin: 0;
  color: #666;
`;

const RemoveAddedPropertyButton = styled.button`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const SelectableDiv = styled.div`
  cursor: pointer;
  padding: 10px;
  margin: 1em 0;
  border-radius: 8px;
  border: 1px solid #dedede;
  position: relative;
`;

const TickBox = styled.span`
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 2.4px solid #dedede;
  border-radius: 100px;
  flex-shrink: 0;
  &::after {
    content: '';
    display: ${props => props.$isSelected ? 'block' : 'none'};
    width: 32px;
    height: 32px;
    background-image: url(${tick});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: -15px;
    left: -4px;
  }
`;

const OptionText = styled.div`
  margin-left: 2em;
  font-size: 16px;
  letter-spacing: -.5px;
  line-height: 140%;
  opacity: .8;
  font-weight: 500;
`;

const SelectableCheckbox = ({ label, onToggle, isSelected }) => {
  return (
    <SelectableDiv onClick={() => onToggle(label)}>
      <TickBox $isSelected={isSelected} />
      <OptionText>{label}</OptionText>
    </SelectableDiv>
  );
};

const MortgageSection = styled.div`
  margin-top: 1em;
`;

const MortgageInputLabel = styled.label`
  font-weight: 600;
  margin-bottom: 0.5em;
  display: block;
`;

const MortgageInputWrapper = styled.div`
  margin-bottom: 1em;
`;

// Main component
function SinglePageApplication() {
  const [docId, setDocId] = useState("");
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    // Ownership structure
    isPersonalOwnership: false,
    isCompanyOrTrustOwnership: false,
    isJointOwnership: false,
    ownerCount: 1,
    personalDetails: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      residentialAddress: '',
      dob: ''
    },
    investmentProperties: [],
    propertyManagerDetails: {
      agency: '',
      agentsName: '',
      phone: '',
      email: ''
    },
    hasPropertyManager: '',
    KYC: {
      licenceNum: '',
      licenceVer: ''
    },
    consents: {
      privacyPolicyConsent: false,
      marketingConsent: false,
      creditCheckAndIdConsent: false
    },
    additionalOwners: [],
    weeklyRent: '',
    monthsAdvance: '',
    combinedWages: '',
    useOfFunds: '',
    timeline: '',
  });

  const navigate = useNavigate();

  // Handlers for form inputs
  const handleInputChange = (section, key, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [key]: value
      }
    }));
  };

  // Handle adding investment properties
  const [newProperty, setNewProperty] = useState({ address: '', type: '', mortgageBalance: '', interestRate: '', mortgageType: '' });

  const addProperty = () => {
    if (newProperty.address && newProperty.type) {
      setFormData(prev => ({
        ...prev,
        investmentProperties: [...prev.investmentProperties, { ...newProperty }]
      }));
      setNewProperty({ address: '', type: '', mortgageBalance: '', interestRate: '', mortgageType: '' });
      setError('');
    } else {
      toast.error('Please fill in both address and property type.', {
        style: {
          fontSize: '14px',
          fontWeight: '600',
          color: '#000',
          backgroundColor: '#FFC701'
        }
      });
    }
  };

  // Handle mortgage details change
  const handleMortgageDetailChange = (index, field, value) => {
    const updatedProperties = [...formData.investmentProperties];
    updatedProperties[index] = {
      ...updatedProperties[index],
      [field]: value
    };
    setFormData(prev => ({
      ...prev,
      investmentProperties: updatedProperties
    }));
  };

  // Remove property function remains the same
  const removeProperty = (index) => {
    setFormData(prev => ({
      ...prev,
      investmentProperties: prev.investmentProperties.filter((_, i) => i !== index)
    }));
  };

  // Handle consents
  const toggleConsent = (consentType) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      consents: {
        ...prevFormData.consents,
        [consentType]: !prevFormData.consents[consentType]
      }
    }));
  };

  // Handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.weeklyRent) {
      toast.error('Please enter your weekly rent.', {
        style: {
          fontSize: '14px',
          fontWeight: '600',
          color: '#000',
          backgroundColor: '#FFC701'
        }
      });
      return;
    }

    if (!formData.personalDetails.firstName || !formData.personalDetails.lastName || !formData.personalDetails.email || !formData.personalDetails.phone) {
      toast.error('Please fill in all personal details.', {
        style: {
          fontSize: '14px',
          fontWeight: '600',
          color: '#000',
          backgroundColor: '#FFC701'
        }
      });
      return;
    }

    if (formData.investmentProperties.length === 0) {
      toast.error('Please add at least one investment property.', {
        style: {
          fontSize: '14px',
          fontWeight: '600',
          color: '#000',
          backgroundColor: '#FFC701'
        }
      });
      return;
    }

    if (!formData.consents.privacyPolicyConsent || !formData.consents.creditCheckAndIdConsent) {
      toast.error('Please agree to the privacy policy and consent to a credit check.', {
        style: {
          fontSize: '14px',
          fontWeight: '600',
          color: '#000',
          backgroundColor: '#FFC701'
        }
      });
      return;
    }

    // If validation passes, submit the form
    try {
      const docRef = await addDoc(collection(db, 'Applications'), formData);
      setDocId(docRef.id);
      sendSlackNotification(formData);
      handleSendEmail(formData);
      handleSendUserEmail(docRef.id, formData);
      // Navigate to a success page or show success message
      navigate('/application-success');
    } catch (error) {
      toast.error('An error occurred while submitting your application. Please try again.');
      console.error(error);
    }
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <WrapperSmall>
          {/* Weekly Rent Input */}
          <div className="titleContainer">
            <h2>Let's get started unlocking your future rental income.</h2>
          </div>
          <label htmlFor="weeklyRent">Your Weekly Rental Income</label>
          <StyledInputWrapper>
            <StyledInputWeekly
              id="weeklyRent"
              name="weeklyRent"
              aria-label="weeklyRent"
              autoFocus
              type="number"
              placeholder="Weekly Rent"
              value={formData.weeklyRent || ''}
              onChange={e => setFormData({ ...formData, weeklyRent: parseFloat(e.target.value) })}
            />
          </StyledInputWrapper>

          {/* Advance Amount Input */}
          <label htmlFor="monthsAdvance">Desired Advance Period (Months)</label>
          <StyledInput
            id="monthsAdvance"
            name="monthsAdvance"
            type="number"
            placeholder="Months"
            value={formData.monthsAdvance || ''}
            onChange={e => setFormData({ ...formData, monthsAdvance: parseInt(e.target.value, 10) })}
          />

          {/* Use of Funds and Timeline */}
          <div className="titleContainer">
            <h2>Tell us more about your master plan.</h2>
            <p>What do you want to do with the funds and what is your timeline?</p>
          </div>
          <select
            id="useOfFunds"
            name="useOfFunds"
            value={formData.useOfFunds}
            onChange={e => setFormData({ ...formData, useOfFunds: e.target.value })}
            aria-label="Use of Funds"
          >
            <option value="">Select your purpose</option>
            <option value="Purchase furniture">Purchase Furniture</option>
            <option value="Fund a deposit">Fund a deposit</option>
            <option value="Renovate another property">Renovate another property</option>
            <option value="Purchase a Minor Dwelling/Tiny Home">Purchase a Minor Dwelling/Tiny Home</option>
            <option value="Maintenance">Maintenance</option>
            <option value="Invest in the stock market">Invest in the stock market</option>
            <option value="Manage cashflow">Manage Cashflow</option>
            <option value="Personal use">Personal Use</option>
            <option value="Other">Other</option>
          </select>

          <select
            id="timeline"
            name="timeline"
            value={formData.timeline}
            onChange={e => setFormData({ ...formData, timeline: e.target.value })}
            aria-label="Timeline"
          >
            <option value="">Select your timeline</option>
            <option value="Immediate">Immediate</option>
            <option value="Within 3 Months">Within 3 Months</option>
            <option value="Within 6 Months">Within 6 Months</option>
            <option value="Within a Year">Within a Year</option>
          </select>

          {/* Personal Details */}
          <div className="titleContainer">
            <h2>Personal Details</h2>
            <p>Let's get to know each other.</p>
          </div>
          <StyledInput
            id="firstName"
            name="firstName"
            type="text"
            placeholder="First Name"
            value={formData.personalDetails.firstName}
            onChange={e => handleInputChange('personalDetails', 'firstName', e.target.value)}
            autoComplete="given-name"
            aria-label="First Name"
          />
          <StyledInput
            id="lastName"
            name="lastName"
            type="text"
            placeholder="Last Name"
            value={formData.personalDetails.lastName}
            onChange={e => handleInputChange('personalDetails', 'lastName', e.target.value)}
            autoComplete="family-name"
            aria-label="Last Name"
          />
          <StyledInput
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            value={formData.personalDetails.email}
            onChange={e => handleInputChange('personalDetails', 'email', e.target.value)}
            autoComplete="email"
            aria-label="Email"
          />
          <StyledInput
            id="phone"
            name="phone"
            type="tel"
            placeholder="Phone"
            value={formData.personalDetails.phone}
            onChange={e => handleInputChange('personalDetails', 'phone', e.target.value)}
            autoComplete="tel"
            aria-label="Phone"
          />

          {/* Investment Properties */}
          <div className="titleContainer">
            <h2>Your Properties</h2>
            <p>Please add all your properties below.</p>
          </div>
          <Autocomplete
            apiKey={String(process.env.REACT_APP_GOOGLE_API_KEY)}
            onPlaceSelected={(place) => {
              setNewProperty({ ...newProperty, address: place.formatted_address });
            }}
            options={{
              types: ['address'],
              componentRestrictions: { country: "nz" }
            }}
            placeholder="Investment Address"
            value={newProperty.address}
            onChange={(e) => setNewProperty({ ...newProperty, address: e.target.value })}
            style={{
              borderRadius: '100px',
              border: '1px solid #dedede',
              fontSize: '16px',
              fontWeight: '500',
              background: '#fff',
              height: '48px',
              marginBottom: '1em',
              outlineOffset: '1.6px',
              position: 'relative',
              display: 'block',
              textAlign: 'left'
            }}
          />
          <select
            id="propertyType"
            name="propertyType"
            value={newProperty.type}
            onChange={e => setNewProperty({ ...newProperty, type: e.target.value })}
          >
            <option value="" disabled>What type of property is it?</option>
            <option value="Long Term Rental">Long Term Rental</option>
            <option value="Short Term Rental">Short Term Rental</option>
            <option value="Rental Arbitrage">Rent to Rent / Short term rental Arbitrage</option>
            <option value="Owner Occupier">Owner Occupier</option>
          </select>
          <ButtonRoundedLight type="button" onClick={addProperty}><Plus color="black" size="19px"/>Add Property</ButtonRoundedLight>

          {/* List of Properties with Mortgage Details */}
          {formData.investmentProperties.length > 0 && <label>Properties:</label>}
          {formData.investmentProperties.map((property, index) => (
            <AddedPropertyCard key={index}>
              <AddedPropertyContent>
                <AddedPropertyTitle>{property.address}</AddedPropertyTitle>
                <AddedPropertyDescription>{property.type}</AddedPropertyDescription>
                {/* Mortgage Details for each property */}
                <MortgageSection>
                  <h4>Mortgage Details</h4>
                  <MortgageInputWrapper>
                    <MortgageInputLabel htmlFor={`mortgageBalance-${index}`}>Mortgage Balance:</MortgageInputLabel>
                    <StyledInput
                      id={`mortgageBalance-${index}`}
                      name={`mortgageBalance-${index}`}
                      type="number"
                      placeholder="Mortgage Balance"
                      value={property.mortgageBalance || ''}
                      onChange={e => handleMortgageDetailChange(index, 'mortgageBalance', e.target.value)}
                    />
                  </MortgageInputWrapper>
                  <MortgageInputWrapper>
                    <MortgageInputLabel htmlFor={`interestRate-${index}`}>Interest Rate (%):</MortgageInputLabel>
                    <StyledInput
                      id={`interestRate-${index}`}
                      name={`interestRate-${index}`}
                      type="number"
                      placeholder="Interest Rate"
                      step="0.01"
                      value={property.interestRate || ''}
                      onChange={e => handleMortgageDetailChange(index, 'interestRate', e.target.value)}
                    />
                  </MortgageInputWrapper>
                  <MortgageInputWrapper>
                    <MortgageInputLabel htmlFor={`mortgageType-${index}`}>Mortgage Type:</MortgageInputLabel>
                    <select
                      id={`mortgageType-${index}`}
                      name={`mortgageType-${index}`}
                      value={property.mortgageType || ''}
                      onChange={e => handleMortgageDetailChange(index, 'mortgageType', e.target.value)}
                    >
                      <option value="" disabled>Select Mortgage Type</option>
                      <option value="Interest Only">Interest Only</option>
                      <option value="Principal and Interest">Principal and Interest</option>
                    </select>
                  </MortgageInputWrapper>
                </MortgageSection>
              </AddedPropertyContent>
              <RemoveAddedPropertyButton onClick={() => removeProperty(index)}>
                <X size={16} color="black"/>
              </RemoveAddedPropertyButton>
            </AddedPropertyCard>
          ))}

          {/* Combined Wages */}
          <div className="titleContainer">
            <h2>Combined Wages</h2>
            <p>Please enter the combined net income (after all taxes and deductions) of you and any joint owners.</p>
          </div>
          <StyledInput
            id="combinedWages"
            name="combinedWages"
            type="number"
            placeholder="Combined Wages"
            value={formData.combinedWages || ''}
            onChange={e => setFormData({ ...formData, combinedWages: parseFloat(e.target.value) })}
            aria-label="Combined Wages"
          />

          {/* Identity Verification */}
          <div className="titleContainer">
            <h2>Identity Verification</h2>
            <p>Add your details below so we can verify your identity.</p>
          </div>
          <StyledInput
            id="licenceNum"
            name="licenceNum"
            type="text"
            placeholder="Driver's License Number"
            value={formData.KYC.licenceNum}
            onChange={e => handleInputChange('KYC', 'licenceNum', e.target.value)}
            required
            aria-label="Driver's License Number"
          />
          <StyledInput
            id="licenceVer"
            name="licenceVer"
            type="text"
            placeholder="Driver's License Version"
            value={formData.KYC.licenceVer}
            onChange={e => handleInputChange('KYC', 'licenceVer', e.target.value)}
            required
            aria-label="Driver's License Version"
          />
          <Autocomplete
            apiKey={String(process.env.REACT_APP_GOOGLE_API_KEY)}
            onPlaceSelected={(place) => {
              handleInputChange('personalDetails', 'residentialAddress', place.formatted_address);
            }}
            options={{
              types: ['address'],
              componentRestrictions: { country: "nz" }
            }}
            placeholder="Current Residential Address"
            value={formData.personalDetails.residentialAddress}
            onChange={(e) => handleInputChange('personalDetails', 'residentialAddress', e.target.value)}
            style={{
              borderRadius: '100px',
              border: '1px solid #dedede',
              fontSize: '16px',
              fontWeight: '500',
              background: '#fff',
              height: '48px',
              marginBottom: '1em',
              outlineOffset: '1.6px',
              position: 'relative',
              display: 'block',
              textAlign: 'left'
            }}
          />
          <StyledInput
            id="dob"
            name="dob"
            type="date"
            placeholder="Date of Birth"
            value={formData.personalDetails.dob}
            onChange={e => handleInputChange('personalDetails', 'dob', e.target.value)}
            required
            aria-label="Date of Birth"
          />

          {/* Privacy Policy & Consents */}
          <div className="titleContainer">
            <h2>Privacy Policy & Consents</h2>
            <p>We need your consent to run a soft credit check, which will not affect your credit score, and to verify your identity. You can view our <Link to="/privacy-policy" target="_blank">privacy policy</Link> for more details.</p>
          </div>
          <SelectableCheckbox
            id="privacyPolicy"
            label="I agree to LeFi's privacy policy"
            isSelected={formData.consents.privacyPolicyConsent}
            onToggle={() => toggleConsent('privacyPolicyConsent')}
          />
          <SelectableCheckbox
            id="marketingConsent"
            label="I consent to receiving marketing emails from LeFi"
            isSelected={formData.consents.marketingConsent}
            onToggle={() => toggleConsent('marketingConsent')}
          />
          <SelectableCheckbox
            id="creditCheck"
            label="I consent to LeFi conducting a soft credit check on me, and collecting other information about me from third parties like identity verification providers, property searches, to assess my eligibility for an advance and to verify the information I provide to LeFi."
            isSelected={formData.consents.creditCheckAndIdConsent}
            onToggle={() => toggleConsent('creditCheckAndIdConsent')}
          />

          {/* Submit Button */}
          <ButtonRoundedText type="submit">Submit your Application</ButtonRoundedText>
        </WrapperSmall>
      </form>
      <Toaster
        position="top-right"
        reverseOrder={false}
        containerStyle={{
          top: 64,
          left: 16,
          bottom: 16,
          right: 16,
        }}
      />
    </>
  );
}

export default SinglePageApplication; 